<template>
	<div class="page-not-found">
		<img src="@/assets/logo.png">

		<h1>
			{{ $t('Page not found') }}
		</h1>

		<AppButton
			modifier="large-primary"
			@click="$router.push('/dashboard')"
		>
			{{ $t('Go Back') }}
		</AppButton>
	</div>
</template>
<script>
export default {
	name: 'PageNotFound',

	async mounted () {
		await this.$nextTick()
		this.$emit('mounted')
	}
}
</script>

<style lang="scss" scoped>
	.page-not-found {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		height: 100%;
		padding: 50px 0;

		h1 {
			font-size: 40px;
			color: #777;
			margin: 30px 0;
		}

	}
</style>
